<template>
    <!-- The video -->
    <div class="video-wrapper h-screen" @click="init()">
        <video autoplay loop muted class="w-full">
            <source :src="config.video_landing_url" type="video/mp4" />Your browser does not support the video tag.
        </video>
    </div>

    <div class="kiosk-landing-language-selection flex justify-center items-center fixed z-100">
        <div>
            <h3 class="text-center text-black mb-1">{{ $t('translations.select_your_language') }}</h3>
            <ul class="grid grid-cols-3 mb-2 justify-center items-center list-none">
                <li
                    class="cursor-pointer flex justify-center"
                    :class="currentLang === locale ? 'order-1' : 'order-2'"
                    v-for="locale in $i18n.availableLocales"
                    :key="`locale-${locale}`"
                    @click="setLanguage(locale)"
                >
                    <img
                        class="object-cover"
                        :src="`/img/flags/${locale}.gif`"
                        :alt="$t(`translations.language.${locale}`)"
                    />
                </li>
            </ul>
        </div>
    </div>
    <!-- modals -->
    <scanner @initTransaction="init()" :initTransaction="true" :hideFakeScan="true"/>
</template>

<script>
//libs
import * as _state from "../lib/state";
import * as _log from '../lib/log';
import * as _transaction from '../lib/transaction';

// components
import Scanner from '../components/Scanner.vue';
// set the logger
const logger = _log.get('LANDING');

export default {
    name: 'landing',
    components: {
        Scanner,
    },

    data () {
        return {
            initpage: 'menu.selection'
        }
    },

    mounted() {
        // reboot?
        if (this.config.reboot === true) {
            this.$router.replace({ name: 'boot', params: { browserreload: 1 } })
        }

        // set default language
        // this.setLanguageToDefault();


        //go to menu.selection if snoeien
        if (this.config.theme.user_mode === 'snoeien') {
            return this.init();
        }

        // set the initpage
        if (this.config.theme.flow == 'default') {
            logger.log('flow -> menu.selection (default)')();
            this.initpage = 'menu.selection';
        }
        else if (this.config.theme.flow == 'barcodescanner') {
            logger.log('flow -> barcode.scanner')();
            this.initpage = 'barcode.scanner';
        }
    },

    methods: {

        init() {

            // clear old payment
            // paymentClear();

            // create a new transaction
            _transaction.init();

            // redirect/replace
            this.$router.replace({ name: this.initpage });
        },
        setLanguage (language) {
            try {
                const config = _state.get('config/getConfig') || {};
                config.language = language;
                _state.set('config/setConfig', config);

                this.$i18n.locale = language;
                this.init();

            } catch (error) {
                logger.error('Failed to set language', error);
            }
        },
        setLanguageToDefault() {
            let config = this.config

            // set the language back to the default
            config.language = config.language_default

            // this.$i18n.locale = config.language;

            _state.set('config/setConfig', config);
        }
    },

    computed: {
        config() {
            return _state.get('config/getConfig')
        },
        currentLang () {
            return  this.config.language || this.$i18n.locale || this.config.language_default;
        },
    }
}
</script>
