<template>
    <div class="flex justify-end">
        <div class="language-switcher relative inline-block">
            <a class="cursor-pointer flex flex-row-reverse justify-start" @click="() => isChooseLanguage = !isChooseLanguage">
                <img :src="`/img/flags/${currentLang}.gif`"  :alt="$t(`translations.language.${currentLang}`)"/> {{ $t(`translations.language.${currentLang}`) }}
            </a>
            <div class="locale-changer absolute z-100 right top-0 left-0 w-full h-full" v-show="isChooseLanguage">
                <ul class="flex flex-col list-none absolute">
                    <li
                        class="flex gap-2 hover:bg-gray-200 p-2 cursor-pointer"
                        v-for="locale in $i18n.availableLocales"
                        :key="`locale-${locale}`"
                        @click="() => {
                          setLanguage(locale);
                          isChooseLanguage = false;
                        }"
                    >
                        <img class="w-8 max-h-7 object-contain" :src="`/img/flags/${locale}.gif`"  :alt="$t(`translations.language.${locale}`)"/>
                        <span>{{ $t(`translations.language.${locale}`) }}</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
// libs
import * as _log from '../lib/log';
import * as _state from "../lib/state";

// set the logger
const logger = _log.get('LanguageSelection');

export default {
    name: 'language.selection',
    data () {
        return {
            isChooseLanguage: false,
        };
    },
    methods: {
        setLanguage (language) {
          try {
            const config = _state.get('config/getConfig') || {};
            config.language = language;
            _state.set('config/setConfig', config);

            this.$i18n.locale = language;

          } catch (error) {
            logger.error('Failed to set language', error);
          }
        },
    },
    computed: {
        config () {
            return _state.get('config/getConfig');
        },
        currentLang () {
          return  this.config.language || this.$i18n.locale || this.config.language_default;
        },
    },

};
</script>