import { now } from "moment";
import md5 from 'js-md5';
import * as _api from './api';
import * as _elockers from '../lib/elockers';
import * as _log from '../lib/log';
import * as _printer from "../lib/printer";
import * as _state from "../lib/state";
import * as _transaction_receipt from '../lib/receipts/transaction';

// set the logger
const logger = _log.get('TRANSACTION');

const init = () => {
    let transaction = {
        id: '',
        lines: [],

        coupons: [],
        payments: [],
        tickets: [],
        vouchers: [],
        duration: 0,
        start_time: 0,

        status: '',

        number_of_items: 0,
        total_without_discount: 0,
        total: 0,
    };
    _state.set('transaction/setTransaction', transaction);

    logger.log('start transaction')();
}

const cancel = async(clear_transaction = false, send_transaction = true) => {

    let transaction = _state.get('transaction/getTransaction');

    transaction.status = 'canceled';

    // release all tickets
    if (clear_transaction === true) {
        let ticket_ids = transaction.lines
            .map(line => {
                return line.tickets.filter(ticket => ticket.vendor == 'elockers' && !ln.details.next).map(ticket => {
                    return ticket.ticket_id
                });
            })
            .flat();

        if (ticket_ids.length > 0) {
            _elockers.release(ticket_ids);
        }
    }

    // set the transaction
    _state.set('transaction/setTransaction', transaction);

    // send to server (when transaction has lines or payments)
    if (send_transaction && (transaction.lines.length > 0 || transaction.payments.length > 0)) {
        await send();
    }

    // release checkout
    _state.setField('status/getStatus', 'status/setStatus', 'basket_locked', false);
    _state.setField('status/getStatus', 'status/setStatus', 'item_loading', false);

    logger.log('transaction canceled')();

    // clear the transaction
    if (clear_transaction === true) {
        clear();
    }

    // reset the transaction to continue
    else {
        transaction.transaction_id = '';
        transaction.status = '';
        transaction.payments = [];

        // set totals
        setTotal(transaction);

        // set the transaction
        _state.set('transaction/setTransaction', transaction);
    }
}


const cancelStoredTransaction = async() => {
    let transaction = _state.get('transaction/getTransaction');

    // post the transaction to api
    let response = await _api.post('transaction/cancel', {
        transaction_id: transaction.id
    });

    if (response && response.result === 'success') {

        logger.log('transaction canceled')();

        transaction.transaction_id = '';
        transaction.status = '';

        // set the transaction
        _state.set('transaction/setTransaction', transaction);

        return true;
    }
    else {
        setId(null)

        logger.error('transaction not canceled')();

        return false;
    }
}


// const cancelPayments = async () => {

//     let transaction = _state.get('transaction/getTransaction');

//     transaction.status = 'canceled';

//     // release all tickets
//     // move to server/api ??
//     transaction.lines
//         .map(line => {
//             return line.tickets.filter(ticket => ticket.vendor == 'elockers');
//         })
//         .flat()
//         .map(ticket => {
//             let response = _elockers.release(ticket);
//             // await new Promise(r => setTimeout(r, 2000));
//             console.log(response);
//         });

//     // set the transaction
//     _state.set('transaction/setTransaction', transaction);

//     // send to server
//     await send();

//     transaction.transaction_id = '';
//     transaction.payments = [];

//     setTotal(transaction);

//     _state.set('transaction/setTransaction', transaction);

//     // release checkout
//     _state.setField('status/getStatus', 'status/setStatus', 'basket_locked', false);

//     logger.log('transaction canceled (send)')();
// }

const clear = () => {
    if (window.autoCancelTimer) {
        clearTimeout(window.autoCancelTimer);
        window.autoCancelTimer = null;
    }

    // todo: release all tickets?
    init();
    // _state.set('transaction/setTransaction', {});

    logger.log('transaction cleared')();
}

const setStatus = (status) => {
    // let transaction = _state.get('transaction/getTransaction');

    // transaction.status = status;

    // _state.set('transaction/setTransaction', transaction);
    _state.setField('transaction/getTransaction', 'transaction/setTransaction', 'status', status);

    logger.log('transaction status set', status)();
}

const setId = (id) => {
    // let transaction = _state.get('transaction/getTransaction');

    // transaction.id = id;

    // _state.set('transaction/setTransaction', transaction);
    _state.setField('transaction/getTransaction', 'transaction/setTransaction', 'id', id);

    logger.log('transaction id set', id)();
}

// items
const addItem = async (data) => {
    let transaction = _state.get('transaction/getTransaction');


    if (transaction.lines.length === 0) {
        transaction.start_time = Date.now();
    }

    // get the lines
    let lines = transaction.lines;

    // line exists?
    let line = lines.find(line => line.id === data.id && line.parent_id === data.parent_id)

    // create new line
    let created = false;
    if (!line) {

        created = true;

        let len = lines.push({
            id: data.id,
            parent_id: data.parent_id,
            cashfunction_id: data.cashfunction_id,
            wallet_id: data.wallet_id,
            wallettoken_id: data.wallettoken_id,
            type: data.type,
            item_id: data.item_id,
            item_parent_id: data.item_parent_id,
            addon: data.addon,
            editable: (data.composed_child || data.addon) ? false : true,
            composed_child: data.composed_child,
            quantity: data.quantity - 1,
            quantity_init: data.quantity,
            barcode: data.barcode,
            description: data.description,
            description_translations: data.description_translations,
            kitchen_groceries: data.kitchen_groceries,
            taxrate: data.taxrate,
            coin_amount: data.coin_amount ? data.coin_amount : 0,
            rate: data.rate,
            rate_without_discount: data.rate,
            discount_amount: 0,
            total: (data.rate * data.quantity),//.toFixed(2),
            thumbnail:  data.thumbnail,
            tickets_elockers: data.tickets_elockers ?? [],
            variant_id:  data.variant_id,
            variant_label:  data.variant_label,

            elockers_active: data.elockers_active,
            elockers_type: data.elockers_type,
            tickets: [],
        });

        line = lines[len - 1];
    }

    // store the transaction
    _state.set('transaction/setTransaction', transaction);

    await addQuantity(line, created);
}

const addQuantity = async (line, created = false) => {
    // get the transaction
    let transaction = _state.get('transaction/getTransaction');

    let tickets = false;

    // reserve lockers
    if (line.elockers_active && line.elockers_type) {
        line.loading = true;

        _state.setField('status/getStatus', 'status/setStatus', 'item_loading', line.item_id);

        tickets = await _elockers.reserve(line.elockers_type);

        _state.setField('status/getStatus', 'status/setStatus', 'item_loading', false);

        if (tickets === false) {
            // delete line when quantity == 0
            if (line.quantity === 0) {
                let lines_delete = transaction.lines.filter(ln => {
                    return line.id == ln.id || ln.parent_id == line.id
                })

                lines_delete.forEach(ln => {
                    transaction.lines.splice(transaction.lines.indexOf(ln), 1)
                })

                // store the transaction
                _state.set('transaction/setTransaction', transaction);
            }
            return;
        }
    }

    // set the quantity of the line
    transaction.lines.filter((ln) => {
        return line.id == ln.id && (ln.parent_id == null || created == true)
    }).map(ln => {
        ln.quantity = ln.quantity + 1;
        ln.total = ln.quantity * ln.rate;
        ln.tickets = tickets ? ln.tickets.concat(tickets) : [];
    });

    // set the quantity of the children
    transaction.lines.filter(ln => {
        return (ln.parent_id != null && line.id == ln.parent_id)
    }).map(ln => {
        ln.quantity = ln.quantity + ln.quantity_init
        ln.total = ln.quantity * ln.rate
    });

    setTotal(transaction);

    // store the transaction
    _state.set('transaction/setTransaction', transaction);

    _state.setField('status/getStatus', 'status/setStatus', 'item_loading', false);
    updateDefaultItemsFee();
}

const substractQuantity = async (line) => {

    // get the transaction
    let transaction = _state.get('transaction/getTransaction');


    let tickets = [];
    // reserve lockers
    if (line.elockers_active && line.elockers_type) {

        tickets = line.tickets.filter(ln => ln.vendor == 'elockers' && ln.details.next == false);

        if (tickets[0]) {
            let response = await _elockers.release([tickets[tickets.length -1].ticket_id]);

            if (tickets === false) {
                return;
            }
            tickets.pop();
        }
    }

    // delete line
    if (line.quantity == 1) {
        let lines_delete = transaction.lines.filter(ln => {
            return line.id == ln.id || ln.parent_id == line.id
        })

        lines_delete.forEach(ln => {
            transaction.lines.splice(transaction.lines.indexOf(ln), 1)
        })
    }
    else {

        // set the quantity of the line
        transaction.lines.filter(ln => {
            return line.id == ln.id
        }).map(ln => {
            ln.quantity = ln.quantity - 1
            ln.total = ln.quantity * ln.rate
            ln.tickets = tickets;
        });

        // set the quantity of the children
        transaction.lines.filter(ln => {
            return ln.parent_id != null && line.id == ln.parent_id
        }).map(ln => {
            ln.quantity = ln.quantity - ln.quantity_init
            ln.total = ln.quantity * ln.rate
        });
    }

    setTotal(transaction);

    // store the transaction
    _state.set('transaction/setTransaction', transaction);
    updateDefaultItemsFee();
}

const updateDefaultItemsFee = () => {
    let defaultItems = _state.get('config/getConfig').default_items;

    if (defaultItems.length <= 0) {
        return;
    }

    let transaction = _state.get('transaction/getTransaction');

    let total = 0;
    transaction.lines.forEach(function (line) {
        if (line.defaultItem) {
            return;
        }

        total += line.total;
    });

    let lines_delete = transaction.lines.filter(line => {
        if (line.defaultItem){
            return line;
        }
    })

    if (lines_delete.length > 0) {
        lines_delete.forEach(ln => {
            transaction.lines.splice(transaction.lines.indexOf(ln), 1)
        })
        setTotal(transaction);
    }

    if (total > 0) {
        defaultItems.forEach(defaultItem => {
            let item = defaultItem.item;
            let rate = 0;

            if (defaultItem.type === 'percentage') {
                rate = total * (defaultItem.amount / 100);
            } else if (defaultItem.type === 'fixed') {
                rate = defaultItem.amount;
            } else {
                if (item.rate >= 0) {
                    rate = item.rate;
                }
            }

            let description = item.attributes.description;
            if (defaultItem.type === 'percentage') {
                description = `${description} (${defaultItem.amount}%)`;
            }

            if (!rate >= 0) {
                let data = {
                    parent_id: null,
                    item_id: item.id,
                    item_parent_id: item.attributes.parent_id,
                    defaultItem: true,
                    addon: false,
                    composed_child: false,
                    barcode: item.attributes.barcode,
                    description: description,
                    description_translations: item.attributes.description_translations,
                    thumbnail: item.attributes.thumbnail_url,
                    taxrate: 0,
                    rate_without_discount: parseFloat(rate),
                    rate: parseFloat(rate),
                    kitchen_groceries: item.attributes.kitchen_groceries,

                    discount_amount: 0,
                    total: parseFloat(rate),
                    tickets_elockers: [],
                    variant_id:  item.attributes.variant_id,
                    variant_label:  item.attributes.variant_label,
                    elockers_active: item.attributes.elockers_active,
                    elockers_type: item.attributes.elockers_type,
                    tickets: [],
                }

                data.id = md5(JSON.stringify(data))
                data.quantity = 1;

                transaction.lines.push(data);
            }
        });

        setTotal(transaction);
    }

    _state.set('transaction/setTransaction', transaction);
}

// payments
const addPayment = (payment) => {

    logger.log('payment added', payment)();

    // get the transaction
    let transaction = _state.get('transaction/getTransaction');

    // push the coupon on the transaction
    transaction.payments.push(payment);

    setTotal(transaction);

    // store the transaction
    _state.set('transaction/setTransaction', transaction);
}

// coupons
const addCoupon = (coupon) => {

    logger.log('coupon added', coupon)();

    // get the transaction
    let transaction = _state.get('transaction/getTransaction');

    // push the coupon on the transaction
    transaction.coupons.push(coupon);

    // set the totals
    setTotal(transaction);

    // store the transaction
    _state.set('transaction/setTransaction', transaction);
}

const deleteCoupon = (coupon) => {

    logger.log('coupon deleted', coupon)();

    // get the transaction
    let transaction = _state.get('transaction/getTransaction');

    // delete coupon
    let coupons_delete = transaction.coupons.filter(cp => {
        return coupon.id == cp.id
    })

    coupons_delete.forEach(cp => {
        transaction.coupons.splice(transaction.coupons.indexOf(cp), 1)
    })

    // set the totals
    setTotal(transaction);

    // store the transaction
    _state.set('transaction/setTransaction', transaction);
}

const setTotal = (transaction) => {

    // todo: coupons
    let factor = 1;
    let couponcode = null;
    for (const coupon of transaction.coupons) {
        if (coupon.type == 'discount_percentage_fixed') {
            factor = (1 - coupon.value)
            couponcode = coupon.code
        }
    }

    let number_of_items = 0;
    let total_without_discount = 0;
    let total = 0;

    // calculate the lines
    transaction.lines.map(ln => {
        ln.couponcode = couponcode
        ln.rate = ln.rate_without_discount * factor
        // ln.rate = ln.rate_without_discount;
        ln.total_without_discount = round((ln.rate_without_discount * ln.quantity), 2);
        ln.total = round((ln.rate * ln.quantity), 2);
        ln.discount_amount = ln.total_without_discount - ln.total;

        number_of_items = number_of_items + ln.quantity;
        total_without_discount = total_without_discount + ln.total_without_discount;
        total = total + ln.total;
    })

    // set the totals to the transaction
    transaction.number_of_items = number_of_items;
    transaction.total_without_discount = total_without_discount;
    transaction.total = total;

    // payments
    let payments = transaction.payments.filter(p => p.status == 'paid').map(p => p.amount);
    transaction.total_paid = payments.length ? payments.reduce((a, b) => a + b) : 0;
    transaction.total_unpaid = round(transaction.total - transaction.total_paid)
}

// const transactionStartPayment = (payment)

const send = async () => {
    // get the transaction
    let transaction = _state.get('transaction/getTransaction');

    // post the transaction to api
    let response = await _api.post('transaction', transaction);

    // handle the response
    if (response && response.result === 'success') {
        setId(response.transaction_id)

        logger.log('transaction sent')();

        return true;
    }
    else {
        setId(null)

        logger.error('transaction not sent')();

        return false;
    }
}


const sendByMail = async (email) => {

    // get the transaction
    let transaction = _state.get('transaction/getTransaction');

    // post the transaction to api
    // todo: remove hardcoded transaction_id
    let response = await _api.post('transaction/mail', {
        email: email,
        transaction_id: transaction.id,
    });

    // handle the response
    if (response && response.result === 'success') {
        logger.log('transaction mailed')();

        return true;
    }
    else {

        logger.error('transaction can not be mail')();

        return false;
    }
}


const print = async (email) => {

    // get the transaction
    let transaction = _state.get('transaction/getTransaction');

    // post the transaction to api
    // todo: remove hardcoded transaction_id
    let data = await _api.get('/v1/kiosk/transactions/' + transaction.id);
    // let data = await api.get('/v1/kiosk/transactions/770faff7-ad62-43a6-9e3d-c772eb017d9c');

    // print
    _printer.print(_transaction_receipt.getRequest(data));
    // // handle the response
    // if (response && response.result === 'success') {
    //     logger.log('transaction mailed')();

    //     return true;
    // }
    // else {

    //     logger.error('transaction can not be mail')();

    //     return false;
    // }
}

// todo: move to global place?!
function round(value, decimals = 2) {
    return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
}


export {
    init,

    cancel,
    cancelStoredTransaction,
    clear,
    setStatus,
    setId,

    addItem,
    addQuantity,
    substractQuantity,

    addPayment,
    // cancelPayments,

    addCoupon,
    deleteCoupon,

    print,
    send,
    sendByMail,

    // transactionTicketAdd,
}
