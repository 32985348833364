import md5 from 'js-md5';
import axios from 'axios';
import * as transaction from '../lib/transaction';
import * as _state from "../lib/state";

export default {
    data() {
        return {
            items: [],
            items_handled: [],
            item: '',
            item_index: 0,
            item_composed: false,
            step: '',
            step_addon: 0,
            quantity: 1,
            show_modal: false,
            variant: null,
            variants: [],
            addons: [],
            addons_selected: [],
            kitchen_groceries: [],
            errors: {
                variants: false,
                addons: [],
                kitchen_groceries: [],
            },
        }
    },
    methods: {

        variantSelect(variant) {
            this.variant = variant

            this.confirmVariant()
        },

        async nextStep(from) {
            if (from == 'details') {
                if (this.item.attributes.variants.length > 0) {
                    this.step = 'variants'
                } else {
                    await this.nextStep('variants')
                }
            }
            else if (from == 'variants') {
                if (this.item.attributes.addons.length > 0) {
                    this.step = 'addons'
                    this.step_addon = 0
                    this.addons_selected = []
                } else {
                    await this.nextStep('addons')
                }
            }
            else if (from == 'addons') {

                if (this.item.attributes.kitchen_groceries.length > 0) {
                    this.step = 'kitchen_groceries'
                } else {
                    await this.nextStep('kitchen_groceries')
                }
            }
            else if (from == 'kitchen_groceries' || from == false) {
                this.item_index++

                // set data to item
                this.item.quantity = this.quantity
                this.item.kitchen_groceries = this.kitchen_groceries
                this.item.addons = this.addons
                this.item.variant = this.variant

                // add item to handled items
                this.items_handled.push(this.item)

                // go to next item
                await this.nextItem()
            }
        },

        confirmVariant() {

            this.errors.variants = false

            if (!this.variant) {
                this.errors.variants = true
                return
            }

            this.nextStep('variants')
        },

        confirmAddons() {

             // todo own function
            // errors in addons

            let addons = []
            // let self = this
            let quantity_error = false

            this.errors.addons = [];
            let addon_group = this.addons[this.step_addon]

            // this.addons[this.step_addon].forEach(function(addon_group) {
                // let required = addon_group.required
                let quantity = 0

                addon_group.options.forEach(function(addon) {
                    addon.error = false

                    if (addon.quantity > 0) {
                        quantity += parseFloat(addon.quantity)
                        addons.push(addon)
                    }

                    if ((addon.quantity_min && addon.quantity < addon.quantity_min) || (addon.quantity_max && addon.quantity > addon.quantity_max)) {
                        addon.error = quantity_error = true
                    }
                })

                if ((addon_group.quantity_min && quantity < addon_group.quantity_min) || (addon_group.quantity_max && quantity > addon_group.quantity_max)) {
                    addon_group.error = quantity_error = true
                }
                // if (required && quantity == 0) {
                //     self.errors.addons.push(addon_group.description)
                // }
            // })

            if (this.errors.addons.length > 0 || quantity_error === true) {
                return
            }
            // this.addons = addons
            let self = this
            addons.forEach(function(addon) {
                self.addons_selected.push(addon)
            })

            if (this.step_addon < (this.item.attributes.addons.length - 1)) {
                this.step_addon++
                return
            }

            this.addons = this.addons_selected

            this.nextStep('addons')
        },

        // confirmAddons() {


        //     if (this.step_addon < (this.addons.length - 1)) {
        //         this.step_addon++
        //         console.log('still addon')
        //         return
        //     }
        //      // todo own function
        //     // errors in addons

        //     let addons = []
        //     // let self = this
        //     let quantity_error = false

        //     this.errors.addons = [];

        //     this.addons.forEach(function(addon_group) {
        //         // let required = addon_group.required
        //         let quantity = 0

        //         addon_group.options.forEach(function(addon) {
        //             addon.error = false

        //             if (addon.quantity > 0) {
        //                 quantity += parseFloat(addon.quantity)
        //                 addons.push(addon)
        //             }

        //             if ((addon.quantity_min && addon.quantity < addon.quantity_min) || (addon.quantity_max && addon.quantity > addon.quantity_max)) {
        //                 addon.error = quantity_error = true
        //             }
        //         })

        //         if ((addon_group.quantity_min && quantity < addon_group.quantity_min) || (addon_group.quantity_max && quantity > addon_group.quantity_max)) {
        //             addon_group.error = quantity_error = true
        //         }
        //         // if (required && quantity == 0) {
        //         //     self.errors.addons.push(addon_group.description)
        //         // }
        //     })

        //     if (this.errors.addons.length > 0 || quantity_error === true) {
        //         return
        //     }

        //     this.addons = addons

        //     this.nextStep('addons')
        // },

        confirmKitchenGroceries() {
            // let kitchen_groceries = []
            let self = this

            // reset errors
            this.errors.kitchen_groceries = [];

            this.item.attributes.kitchen_groceries.forEach(function(line) {
                let found = false
                self.kitchen_groceries.forEach(function(selected) {
                    if (selected.description == line.description) {
                        found = true
                    }
                })

                if (!found) {
                    self.errors.kitchen_groceries.push(line.description)
                }
            })

            if (this.errors.kitchen_groceries.length > 0) {
                return
            }

            this.nextStep('kitchen_groceries')
        },

        // Kitchen Groceries functions
        setKitchenGrocery(description, value) {
            // eslint-disable-next-line no-unused-vars
            let found = false
            this.kitchen_groceries.forEach(function(line) {
                if (line.description == description) {
                    found = true
                    line.value = value
                }
            })

            if (!found) {
                this.errors.kitchen_groceries.splice(this.errors.kitchen_groceries.indexOf(description, 0), 1);
                this.kitchen_groceries.push({'description':  description, 'value': value});
            }
        },

        isKitchenGrocerySelected(description, value)
        {
            let selected = false
            this.kitchen_groceries.forEach(function(line) {
                if (line.description == description && line.value == value) {
                    selected = true
                }
            })
            return selected
        },

        // check if field has error
        hasError(field, value)
        {
            let has_error = false
            this.errors[field].forEach(function(error) {
                if (error == value) {
                    has_error = true
                }
            })
            return has_error
        },

        addonRadioSelect(addon, option) {
            addon.options.forEach(addon_option => {
                if (option.id == addon_option.id) {
                    addon_option.quantity = this.quantity
                } else {
                    addon_option.quantity = 0
                }
            })
        },

        // async elockersReserve(type) {
        //     console.log(type)
        //     let reserved = false
        //     console.log('elockers reserve')
        //     let response = await lockerReserve(type);
        //     console.log(response);
        //     return reserved
        // },

        changeAddonQuantity(addon_group, addon, quantity) {
            if ((addon.quantity) + quantity < 0 ) {
                return;
            }

            if (addon.quantity_max) {
                if ((addon.quantity) + quantity > addon.quantity_max) {
                    return;
                }
            }

            if (addon_group.quantity_max) {
                if ((addon.quantity) + quantity > addon_group.quantity_max) {
                    return;
                }
            }

            addon.quantity = parseFloat((addon.quantity)) + quantity
            addon.quantity_init = addon.quantity
        },

        // addItem(quantity = 1) {
        async addItem(item, showDetails = false) {
            this.item_index = 0
            this.item_composed = false
            this.items = []
            this.items_handled = []
            this.quantity = this.initialquantity > 0 ? this.initialquantity : 1

            // clone initial item
            let initialitem = JSON.parse(JSON.stringify(item))

            // let initialitem = JSON.parse(JSON.stringify(this.initialitem))
            // initialitem.quantity = this.initialquantity

            // handle elockers ticket
            // if (initialitem.attributes.elockers_active == true && initialitem.attributes.elockers_type) {
            //     // reserve the ticket
            //     let result_reserve = await lockerReserve(initialitem.attributes.elockers_type);
            //     console.log('result_reserve', result_reserve)
            //     if (result_reserve === false) {
            //         return;
            //     }
            // }

            // check for composed items
            if (initialitem.attributes.composeditems.data.length > 0) {
                this.items = initialitem.attributes.composeditems.data
                this.item_composed = true
            }
            else if(initialitem.attributes.card && initialitem.attributes.card_type === 'cashcard'){
                this.$emit('openWalletAddCreditModal', initialitem);
            }
            else {
                this.items.push(initialitem)
            }
            // select het next item in the array
            await this.nextItem(showDetails)
        },

        async addItemByScannedBarcode(item) {
            this.item_index = 0

            this.items = []
            this.items_handled = []
            this.quantity = 1
            this.items.push(item)
            await this.nextItem()
        },

        async nextItem(showDetails = false) {

            // check if there is a next item
            // when there is a next item handle the item
            if (this.items[this.item_index]) {
                await this.handleItem(this.items[this.item_index], showDetails)

                return
            }
            // when no item is next, set the items to the cart
            else {
                let self = this
                let line_id = null

                if (this.item_composed) {
                    let initialitem = JSON.parse(JSON.stringify(this.initialitem));
                    initialitem.quantity = this.quantity
                    initialitem.variant = null
                    initialitem.addons = []

                    line_id = await this.addItemToCart(initialitem)
                }

                // loop through the handled items
                for (const item of this.items_handled) {
                    // set parent_line_id
                    if (line_id) {
                        item.line_parent_id = line_id
                    }

                    // set composed_child
                    item.composed_child = self.item_composed

                    await self.addItemToCart(item);
                }

                // close the modal
                this.closeModal()
            }

        },

        async handleItem(item, showDetails = false) {


            // set item
            this.item = item

            // reset the values and errors
            this.variant = null;
            this.kitchen_groceries = []
            this.addons = JSON.parse(JSON.stringify(this.item.attributes.addons));
            this.errors = {
                addons: [],
                kitchen_groceries: [],
            }

            // open modal?
            if(this.item.attributes.addons.length > 0 || this.item.attributes.kitchen_groceries.length > 0 || this.item.attributes.variants.length > 0 || showDetails) {
                this.openModal(showDetails)
                // openmodal = true
                return
            }

            // force to end of the wizard
            await this.nextStep(false)
        },

        addQuantity() {
            this.quantity = this.quantity + 1;

            // add addons
            this.addons.forEach((addon) => {
                // addons has only options when addons not yet confirmed
                if (addon.options) {
                    addon.options.forEach((option) => {
                        if (option.quantity > 0) {
                            option.quantity = parseFloat(option.quantity) + parseFloat(option.quantity_init ? option.quantity_init : 1)
                        }
                    })
                } else {
                    if (addon.quantity > 0) {
                        addon.quantity = parseFloat(addon.quantity) + parseFloat(addon.quantity_init ? addon.quantity_init : 1)
                    }
                }
            })
        },
        subQuantity() {
            if (this.quantity > 1) {
                this.quantity = this.quantity - 1;

                // subtract addons
                this.addons.forEach((addon) => {
                    // addons has only options when addons not yet confirmed
                    if (addon.options) {
                        addon.options.forEach((option) => {
                            if (option.quantity > 0) {
                                option.quantity = parseFloat(option.quantity) - parseFloat(option.quantity_init ? option.quantity_init : this.quantity)
                            }
                        })
                    } else {
                        if (addon.quantity > 0) {
                            addon.quantity = parseFloat(addon.quantity) - parseFloat(addon.quantity_init ? addon.quantity_init : this.quantity)
                        }
                    }
                })
            }
        },

        async addItemToCart(item) {
            let self = this

            // set the rate
            let rate = 0
            if (!item.composed_child) {
                rate = item.variant ? item.variant.attributes.price : item.attributes.kiosk_price
            }

            // set the data of the item
            let data = {
                parent_id: item.line_parent_id ? item.line_parent_id : null,
                item_id: item.id,
                item_parent_id: item.parent_id,
                addon: false,
                composed_child: item.composed_child ? item.composed_child : false,
                barcode: item.attributes.barcode,
                description: item.attributes.description + (item.variant ? ' - ' + item.variant.attributes.label : ''),
                description_translations: item.attributes.description_translations,
                thumbnail: item.attributes.thumbnail_url,
                taxrate: item.attributes.taxrate,
                rate_without_discount: rate,
                rate: rate,
                kitchen_groceries:  item.kitchen_groceries,

                variant_id:  item.variant ? item.variant.id : null,
                variant_label:  item.variant ? item.variant.attributes.label : null,

                // addons?!
                addons: item.addons,

                // elockers
                elockers_active: item.attributes.elockers_active,
                elockers_type: item.attributes.elockers_type,
            }

            data.id = md5(JSON.stringify(data))
            data.quantity = item.quantity
            // data.tickets_elockers = item.tickets_elockers

            // this.$store.dispatch('transaction/addItem', data)

            // add the item to the transaction
            await transaction.addItem(data)

            let basket = _state.get('transaction/getTransaction');

            // add linkeditems
            if (item.attributes.linkeditems && item.attributes.linkeditems.kiosk) {
                item.attributes.linkeditems.kiosk.forEach(function (linkeditem) {
                    let data_linkeditem = {
                        parent_id: data.id,
                        item_id: linkeditem.id,
                        item_parent_id: linkeditem.attributes.item_parent_id,
                        addon: true,
                        // uneditable: true,
                        barcode: linkeditem.attributes.barcode,
                        description: linkeditem.attributes.description,
                        thumbnail: null,
                        taxrate: linkeditem.attributes.taxrate,
                        rate: linkeditem.attributes.kiosk_price ? linkeditem.attributes.price : 0,
                        kitchen_groceries: [],
                    };
                    data_linkeditem.id = md5(JSON.stringify(data_linkeditem));
                    data_linkeditem.quantity = parseInt(data.quantity);

                    if (!basket.lines.filter((line) => line.id == data_linkeditem.id).length) {
                        transaction.addItem(data_linkeditem);
                    }
                });
            }

            // add addons
            for (const addon of item.addons) {
                let data_addon = {
                    parent_id: data.id,
                    item_id: addon.item_id,
                    item_parent_id: item.id,
                    addon: true,
                    // quantity: parseInt(addon.quantity),
                    barcode:  addon.barcode,
                    description:  addon.description,
                    thumbnail:  null,
                    taxrate:  addon.taxrate,
                    rate_without_discount:  addon.price,
                    rate:  addon.price,
                    kitchen_groceries:  [],
                }
                data_addon.id = md5(JSON.stringify(data_addon))
                data_addon.quantity = parseInt(addon.quantity)
                // self.$store.dispatch('transaction/addItem', data_addon)

                // add the addon to the transaction
                await transaction.addItem(data_addon)
            }

            // add cashfunctions
            for (const cashfunction of item.attributes?.cashfunctions) {
                let data_cashfunction = {
                    type: 'cashinout',
                    parent_id: data.id,
                    cashfunction_id: cashfunction.id,
                    item_id: null,
                    item_parent_id: null,
                    addon: true,
                    barcode: null,
                    description: cashfunction.description,
                    thumbnail: null,
                    taxrate: 0,
                    rate: cashfunction.price,
                    rate_without_discount: cashfunction.price,
                    kitchen_groceries: [],
                };

                data_cashfunction.id = md5(JSON.stringify(data_cashfunction));
                data_cashfunction.quantity = item.quantity
                data_cashfunction.quantity_init = item.quantity;

                await transaction.addItem(data_cashfunction)
            }

            return data.id
        },

        async openModal(show_details = true) {
            // this.resetValues();

            if (show_details == false) {
                if (this.item.attributes.variants.length > 0) {
                    this.step = 'variants'
                } else {
                    await this.nextStep('variants')
                }
            } else {
                this.step = 'details'
            }

            document.body.classList.add("modal-open");

            // open modal
            this.show_modal = true;
        },

        closeModal() {
            // this.resetValues();

            document.body.classList.remove("modal-open");

            // close modal
            this.imgLoaded = false;
            this.show_modal = false;
        },


        fetchData() {
            return new Promise(resolve => {
                setTimeout(() => {
                resolve('Data fetched!');
                }, 2000);
            });
        }
    },

    computed: {
        config() {
            return _state.get('config/getConfig')
        },
        language () {
            return this.config.language;
        },
    }
}
