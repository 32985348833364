<template>

    <transition name="swipe-up">
        <div v-if="show" class="modal-outer wallet-modal" :class="'is-' + mode">

            <div class="modal-blur" @click="close()"></div>

            <transition name="swipe-up">
                <div class="modal br-receiver" v-if="show_modal">
                    <div class="modalclose" @click="close()" v-if="mode !== 'nfc'">
                        <svg class="h-16 w-16 cursor-pointer" xmlns="http://www.w3.org/2000/svg" fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div>

                    <div class="modalcontent">
                        <div class="toggle-wrap-outer" v-if="modes.length > 1">
                            <!-- <button v-if="show_cancel_button" class="btn btn-primary btn-modal-large cancel-button" @click="abortNFC()">Cancel</button> -->

                            <div class="toggle-wrap" :class="mode + ' ' + indicator">
                                <div class="overlay" @click="toggleMode()"></div>
                                <label class="switch" >
                                    <input type="checkbox">
                                    <span class="slider round"></span>

                                    <template v-for="(mode_tag, index) in modes" :key="index">
                                        <div :class="'option-' + (parseInt(index) + 1) ">{{getNameFromMode(mode_tag)}}</div>
                                    </template>
                                </label>
                            </div>
                        </div>

                        <transition-group :name="direction">
                            <div v-if="mode == 'manual'" class="step-wrapper" :class="mode">
                                <h1>Enter cashcard number manually</h1>

                                <div class="br-receiver">
                                    <div v-if="error" class="">
                                        <p><strong class="text-red-500">Invalid card information!</strong></p>
                                    </div>

                                    <div>
                                        <div class="btn-input-wrapper">
                                            <input type="text" class="br-receiver" v-model="cardnumber" placeholder="Card Number">
                                        </div>

                                        <Numpad ref="numpad" @inputvalue="cardnumber = $event, card = $event"/>

                                        <div class="large-btn-wrapper">
                                            <button class="btn btn-secondary btn-modal-large" @click="checkCardnumber()">Check card</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="mode == 'overview'" class="step-wrapper" :class="mode">
                                <h1>Overview</h1>

                                <div class="br-receiver">
                                    <div v-if="error" class="">
                                        <p><strong class="text-red-500">Invalid card information!</strong></p>
                                    </div>

                                    <div class="card-overview">
                                        <div>
                                            <h3><strong>Card Number:</strong><span> {{ card?.barcode }}</span></h3>
                                            <h3><strong>Current Balance:</strong> <span>{{ card?.balance }}</span></h3>
                                              <h3 class="added-to-card-amount" v-if="topup  == true">
                                                <strong>{{ item.attributes.description }}</strong>
                                                <h1>€ {{ item.attributes.price }}</h1>
                                            </h3>
                                        </div>

                                        <div class="py-10" v-if="topup === true && location_allowed === false">
                                          <p class="font-bold text-center max-w-full">This token is not valid for this location. Please ensure you are using the correction token. Topups cannot be processed with this token here.</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="large-btn-wrapper">
                                    <transition name="swipe-left" v-if="topup == true">
                                        <button :class="[
                                          'btn btn-primary btn-modal-large',
                                          ['opacity-50 cursor-default', !location_allowed]
                                        ]" :disabled="!location_allowed" @click="submit()">Confirm</button>
                                    </transition>

                                    <transition name="swipe-left" v-if="topup == true">
                                        <button v-if="!confirm" class="btn btn-secondary btn-modal-large" @click="close()">Cancel</button>
                                    </transition>

                                    <transition name="swipe-left" v-if="topup == false">
                                        <button class="btn btn-secondary btn-modal-large" @click="close()">Close</button>
                                    </transition>
                                </div>
                            </div>

                            <div v-if="mode == 'qr'" class="step-wrapper" :class="mode">
                                <h1>Scan QR code in scanner</h1>

                                <div class="pin-terminal-arrow-svg-wrapper payment-icon loading" :class="'pending-' + config.theme.pin_terminal_position">
                                    <svg viewBox="0 0 510 488" version="1.1"
                                        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <title>Line 12</title>
                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"
                                        stroke-linecap="round">
                                            <g id="Line-12" transform="translate(15.000000, 15.000000)" stroke="#FFF200"
                                            stroke-width="30">
                                                <path
                                                    d="M0,134 L239.357,334.96 C239.729,335.272 240.271,335.272 240.643,334.96 L480,134"
                                                    id="Path"></path>
                                                <path
                                                    d="M0,-1.42108547e-14 L239.357,200.96 C239.729,201.272 240.271,201.272 240.643,200.96 L480,-1.42108547e-14"
                                                    id="Path"></path>
                                                <path
                                                    d="M0,256 L239.357,456.96 C239.729,457.272 240.271,457.272 240.643,456.96 L480,256"
                                                    id="Path"></path>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                            </div>

                            <div v-if="mode == 'nfc'" class="step-wrapper" :class="mode">
                                <h1>Put cashcard on Pin Terminal</h1>

                                <div class="pin-terminal-arrow-svg-wrapper payment-icon loading" :class="'pending-' + config.theme.pin_terminal_position">
                                    <svg viewBox="0 0 510 488" version="1.1"
                                        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <title>Line 12</title>
                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"
                                        stroke-linecap="round">
                                            <g id="Line-12" transform="translate(15.000000, 15.000000)" stroke="#FFF200"
                                            stroke-width="30">
                                                <path
                                                    d="M0,134 L239.357,334.96 C239.729,335.272 240.271,335.272 240.643,334.96 L480,134"
                                                    id="Path"></path>
                                                <path
                                                    d="M0,-1.42108547e-14 L239.357,200.96 C239.729,201.272 240.271,201.272 240.643,200.96 L480,-1.42108547e-14"
                                                    id="Path"></path>
                                                <path
                                                    d="M0,256 L239.357,456.96 C239.729,457.272 240.271,457.272 240.643,456.96 L480,256"
                                                    id="Path"></path>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                            </div>

                            <div v-if="mode == 'try-again'" class="step-wrapper" :class="mode">
                                <h1>Read failed</h1>

                                <div class="large-btn-wrapper">
                                    <div>
                                        <button v-for="(mode_item, index) in modes.filter(__mode => __mode !== 'manual')" :key="index" class="btn btn-primary btn-modal-large"  @click="switchMode(mode_item)">{{ getNameFromMode(mode_item, mode) }}</button>
                                        <button class="btn btn-secondary btn-modal-large" @click="close()">Cancel</button>
                                     </div>
                                     <button class="btn btn-secondary btn-modal-large"  @click="switchMode('manual')">{{ getNameFromMode('manual') }}</button>
                                </div>
                            </div>

                        </transition-group>
                    </div>
                </div>
            </transition>
        </div>
   </transition>
</template>

<style scoped>


    .confirm {
        padding: 10px;
        background-color: rgb(74, 170, 74);
    }

    .submit {
        padding: 10px;
        background-color: rgb(4, 150, 4);
    }
</style>

<script>
import md5 from 'js-md5';

import db from "../lib/db";
import * as _api from '../lib/api';
import Numpad from './Numpad.vue';
import * as transaction from '../lib/transaction';
import * as _state from '../lib/state';


// mixins
import BarcodeReader from '../mixins/barcode.reader.js';
import PaymentHelper from '../mixins/payment.helper.js';

export default {
    mixins: [BarcodeReader, PaymentHelper],
    emits:['toggleScan', 'loading'],
    props: ['topup'],
    components: {
      Numpad,
    },
    data() {
        return {
            show: false,
            show_modal: false,
            show_cancel_button: false,
            direction: 'pages-forward',
            type: 'checkcard',
            step: 1,
            indicator: 'left',
            modes: [],
            mode: 'qr',
            cardnumber: null,
            cashfunction: null,
            card: null,
            paymentserviceprovider: null,
            item: null,
            error: false,
            wallet_valid: null,
            payment_pin: null,
            location_allowed: true,
        }
    },

    async mounted() {
        if (this.config.theme.wallet_topup_mode) {
            this.modes = this.config.theme.wallet_topup_mode.split(' ');
        } else {
            this.modes = ['manual'];
        }

        // this.modes = ['nfc', 'manual'];

        this.mode = this.modes[0];

        if (this.config.theme.user_mode == 'nfc-online') {
            this.setPinPayment();
        }
    },

    watch: {
        async show() {
            setTimeout(() => {
                this.show_modal = this.show
            }, 200);
        },

        mode() {
            console.log(this.mode);
        }
    },

    methods: {
        async open(type, item = false) {
            this.type = type;
            this.mode = this.modes[0];
            this.show_cancel_button = false

            if (type === 'topUp') {
                this.item = item;
            }

            this.$emit('toggleScan');

            this.initBarcodeScanner();

            this.cashfunction = await this.getCashfunction();

            // this.values = this.cashfunction.attributes.coin_button_values.split(';');


            this.show = true;

            if (this.mode == 'nfc') {
                await this.readNFC();
            }
        },

        async setPinPayment () {
            await db.initialize();
            let paymenttypes = await db.get('paymenttypes');

            this.payment_pin = paymenttypes.find(payementtype => payementtype.attributes.preferred_payment === true
                && (['adyen_pos', 'ccv_pin', 'adyen']).includes(payementtype.attributes.psp_type));
        },

        setTimer() {
            // todo: set to 15
            let seconds = 15;

            const timer = setInterval(() => {
                if (seconds > 0) {
                    seconds--;
                } else {
                    this.show_cancel_button = true;
                    clearInterval(timer);
                }
            }, 1000);
        },

        async switchMode(mode_attr) {

            this.show_cancel_button = false;
            if (mode_attr == 'manual') {

                const position = this.modes.indexOf('manual');
                if (position == 0) {
                    this.indicator = 'left';
                    this.direction = 'pages-back';
                } else if (position == 1) {
                    this.indicator = 'right';
                    this.direction = 'pages-forward'
                }
                this.mode = this.modes[position];
                return;
            }

            else if (mode_attr == this.modes[0]) {
                this.indicator = 'left';
                this.direction = 'pages-back';
                this.mode = this.modes[0];
            }

            else if (mode_attr == this.modes[1]) {
                this.indicator = 'right';
                this.direction = 'pages-forward';
                this.mode = this.modes[1];
            }

            if (this.mode == 'nfc') {
                this.setTimer();

                await this.readNFC();
            }
        },

        async toggleMode() {
            if (this.mode == this.modes[0]) {
                this.indicator = 'right';
                this.mode = this.modes[1];
                this.direction = 'pages-forward';
            } else {
                this.indicator = 'left';
                this.mode = this.modes[0];
                this.direction = 'pages-back';
            }

            if (this.mode == 'nfc') {
                this.setTimer();

                await this.readNFC();
            }
        },

        close(force = false) {
            if (this.mode == 'nfc' && force === false) {
                return false;
            }

            this.show_cancel_button = false;
            this.show = false;
            this.mode = this.modes[0];
            this.indicator = 'left';
            this.mode = this.modes[0];
            this.direction = 'pages-back';
            this.type = 'checkcard';
            this.step = 1;

            this.cardnumber = null,
            this.cashfunction = null,
            this.card = null;
            this.item = null;
            this.error = false;
            this.location_allowed = true;

            // empty cart when closing modal to prevent multiple products in cart
            // transaction.clear();

            this.destroyBarcodeScanner();
            this.$emit('toggleScan');
            this.$emit('loading', false);
        },

        cancelNfc() {
            this.show_cancel_button = false;

            this.direction = '';
            this.mode = 'try-again';
        },

        async getCashfunction () {
            const response = await _api.get('cashfunctions/wallet');

            if (!response) {
                alert('No cashfunctions found!')
                return;
            }

            return response;
        },

        async getPaymentServiceProviderId() {

            const paymenttypes = await db.get('paymenttypes');

            const paymenttype = paymenttypes.find(paymenttype => paymenttype.attributes.psp_type === 'adyen_pos' && paymenttype.attributes.psp_id);

            if (!paymenttype) {
                alert('No payment service provider found!')
                return false;
            }

            return paymenttype.attributes.psp_id;
        },

        async checkCardnumber() {
            if (this.cardnumber.length > 0) {

                await this.checkWallettoken({
                    cardnumber: this.cardnumber,
                });
            }
        },

        async readNFC() {
            try {

                const psp_id = await this.getPaymentServiceProviderId();

                _state.setField('status/getStatus', 'status/setStatus', 'autocancel_disabled', true);

                const response = await _api.post('nfc/read', {
                    psp_id: psp_id, // TODO: get from config
                    reader: 'adyenterminal'
                });

                if (response.result == 'success') {
                    await this.checkWallettoken({
                        chipcard_id: response.data.chipcard_id,
                    });
                } else {
                    this.mode = 'try-again';
                    console.log('readNFC failed')
                }
            } catch (error) {
                    console.log('readNFC error')
            } finally {
                _state.setField('status/getStatus', 'status/setStatus', 'autocancel_disabled', false);
            }
        },

        async abortNFC() {
            const response = await _api.post('nfc/abort', {
                psp_id: 1,
                reader: 'adyenterminal'
            });

            if (response.result == 'success') {
                this.close(true);
            } else {
                alert('abort failed')
            }
        },

        getNameFromMode(tag, originalMode) {
            if (tag == originalMode) {
                return 'Try again';
            }

            if (tag == 'qr') {
                return 'Kaart scannen';
            }

            if (tag == 'manual') {
                return 'Handmatig';
            }

            if (tag == 'nfc') {
                return 'Kaart lezen';
            }
        },

        async barcodeScanned (barcode) {
            this.reset();

            await this.checkWallettoken({
                barcode: barcode,
            });
        },

        async checkWallettoken(data) {
            data.type = this.cashfunction.attributes.coins ? 'coins' : 'cash';
            data.amount = this.transaction.total;

            const response = await _api.post('wallet/token/check', data);

            if (response.result == 'success') {

                const wallet_in_basket = this.transaction.payments.filter(payment => {
                    return payment.wallet_id == response.wallet_id;
                }).length > 0;


                if (wallet_in_basket === true) {
                    this.wallet_valid = false;
                    return;
                }

                this.card = response;
                this.mode = 'overview';
                this.error = false;
            } else {
                this.mode = 'try-again';
                this.card = null;
                this.error = true;

                if(response.data.message === "location not allowed" && this.topup === true) {
                  this.error = false;
                  this.card = response;
                  this.mode = 'overview';
                  this.location_allowed = false;
                }
            }
        },


        reset () {
            this.wallet_valid = null;
        },


        async submit() {
            let rate = 0

            if (!this.item.composed_child) {
                rate = this.item.variant ? this.item.variant.attributes.price : this.item.attributes.kiosk_price
            }

            let data = {
                cashfunction_id: this.cashfunction.id,
                wallet_id: this.card.wallet_id,
                wallettoken_id: this.card.id,
                type: 'cashinout',
                parent_id: null,
                item_id: null,
                item_parent_id: null,
                addon: false,
                editable: true,
                composed_child: false,
                quantity_init: 1,
                barcode: this.item.attributes.barcode,
                description: this.item.attributes.description,
                description_translations: this.item.attributes.description_translations,
                kitchen_groceries: null,
                taxrate: null,
                rate: rate,
                rate_without_discount: rate,
                discount_amount: 0,
                total: rate,//.toFixed(2),
                thumbnail:  null,
                tickets_elockers: [],
                variant_id:  null,
                variant_label:  null,
                coin_amount: this.cashfunction.attributes.coins == true ?  this.item.attributes.price / this.cashfunction.attributes.coin_value : null,

                elockers_active: false,
                elockers_type: null,
                tickets: [],
            }

            data.id = md5(JSON.stringify(data));
            data.quantity = 1;

            await transaction.addItem(data);

            if (this.config.theme.user_mode == 'nfc-online' && this.payment_pin !== null) {
                this.gotoPayment(this.payment_pin.id, this.payment_pin.attributes.name, this.payment_pin.attributes.psp_mode);
            }

            // this.close();
        },
    },

    computed: {
        transaction () {
            return _state.get('transaction/getTransaction');
        },
        config () {
            return _state.get('config/getConfig');
        },
    },
};
</script>
