<template>
    <!-- todo delete (temporary functions) -->
    <!-- <router-link :to="{name:'boot'}">Boot again</router-link> -->
    <div class="navigation relative flex justify-between z-100" :class="['h-' + config.theme.header_height + ' bottom-' + config.theme.header_border] ">



        <div class="navigation-helper-buttons">
            <div v-if="config.background_header_url" class="background-image-filler"
                 :style="[{ backgroundImage: 'url(' + config.background_header_url + ')'},
                 {backgroundPosition: config.theme.header_image_position_x + ' ' + config.theme.header_image_position_y },
                 {backgroundSize: config.theme.header_image_size},
                 {borderBottomWidth: config.theme.header_border_width + 'px'}
                 ]"
            ></div>

            <div v-if="config.theme.wallet_button_in_header"  @click="checkCard()" class="btn btn-primary">
                Check Card
            </div>

            <router-link class="return-button" v-if="backbutton && !basket_locked" :to="{name: backbutton}">
                {{ $t('translations.global.go-back') }}
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" width="40" height="40">
                    <path
                        d="m15.5 0.932-4.3 4.38 14.5 14.6-14.5 14.5 4.3 4.4 14.6-14.6 4.4-4.3-4.4-4.4-14.6-14.6z"></path>
                </svg>
            </router-link>

            <div class="" v-if="!backbutton && paymenttypes_voucher.length > 0 && config.theme.voucher_button_in_header === true">
                <button v-for="(paymenttype, index) in paymenttypes_voucher" :key="index"
                        @click="$refs.modalvoucher.open(paymenttype.id)"
                        class="mr-5 btn btn-primary">
                    <div v-if="paymenttype.attributes.voucher_payment === true">
                        {{ $t('translations.checkout.voucher-balance') }}
                    </div>
                    <div v-else>
                        {{ paymenttype.attributes.name }}
                    </div>
                </button>
            </div>

            <div class="" v-if="config.theme.support_button_in_header === true">
                <button @click="$refs.helpmodal.showModal()" class="mr-5 btn btn-secondary">
                    {{ $t("translations.support.button") }}
                </button>
            </div>
        </div>

        <div>
            <img
                @click="incrementTrigger()"
                class="logo"
                :src="config.logo_url"
            />
        </div>

        <div :class="{ 'pointer-events-none opacity-0' :config.theme.language_switcher_mode !== 'top'}">
            <language-selection />
        </div>

        <div v-if="description_bar" class="description-bar padding-wrapper basket-styles">
            <div class="bar-title">Etiam imperdiet imperdiet orci. Nunc nec neque. Phasellus leo dolor</div>
            <img v-if="description_bar_image" :src="description_bar_image" alt="">
            <span @click="description_bar = !description_bar" class="description-close">+</span>
        </div>
    </div>

    <!-- modals -->
    <modal-voucher ref="modalvoucher" :voucher_in_header="true" v-if="paymenttypes_voucher.length > 0"></modal-voucher>
    <modal-manager ref="modalmanager"></modal-manager>
    <ModalHelp ref="helpmodal"></ModalHelp>
    <modal-wallet-add-credit ref="modalwalletaddcredit" :topup="false"></modal-wallet-add-credit>
</template>


<script>


// libs
import db from "../lib/db";
import * as _state from "../lib/state";
import * as _log from '../lib/log';

//mixin
import VoucherHelper from '../mixins/voucher.helper.js';

// components
import LanguageSelection from "./LanguageSelection.vue";
import ModalManager from "./ModalManager.vue";
import ModalVoucher from '../components/ModalVoucher.vue';
import ModalWalletAddCredit from "./ModalWalletAddCredit.vue";

// ask for help
import ModalHelp from "./ModalHelp.vue"

// set the logger
const logger = _log.get('Navigation');

export default {
    name: 'navigation',
    props: ['backbutton'],
    mixins: [VoucherHelper],
    components: {
        LanguageSelection,
        ModalManager,
        ModalVoucher,
        ModalHelp,
        ModalWalletAddCredit,
    },

    data () {
        return {
            description_bar: false,
            description_bar_image: true,
            click_trigger: 0,
            paymenttypes_voucher: [],
        };
    },

    async mounted () {

        await db.initialize();

        // set the paymenttypes (only preferred)
        let paymenttypes = await db.get('paymenttypes');

        this.setPaymenttypesVoucher(paymenttypes);
    },

    methods: {
        incrementTrigger () {
            this.click_trigger++;

            if (this.click_trigger == 1) {
                var self = this;
                setTimeout(function () {
                    self.click_trigger = 0;
                }, 3000);
            }

            if (this.click_trigger == 5) {
                // open manager modal
                logger.log('open manager modal')();

                this.$refs.modalmanager.login();
            }
        },
        checkCard(){
            this.$refs.modalwalletaddcredit.open('checkCard')
        }
    },
    computed: {
        config () {
            return _state.get('config/getConfig');
        },

        basket_locked () {
            let status = _state.get('status/getStatus');

            return status.basket_locked;
        },
    },
};
</script>
